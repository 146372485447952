var describeAction = (type, handle) => {
  var create;

  switch (handle.length) {
    case 1:
      {
        var action = Object.freeze({
          type,
          payload: undefined
        });

        create = () => action;

        break;
      }

    default:
      create = payload => ({
        type,
        payload
      });

  }

  return Object.assign(create, {
    create,
    type,
    handle,
    isMine: a => a.type === type
  });
};
function createReducer(descriptions, initialState) {
  var map = {};

  for (var desc of descriptions) {
    map[desc.type] = desc.handle;
  }

  return function reducer(prev, action) {
    prev = prev || initialState;
    var handle = map[action.type];

    if (!handle) {
      return prev;
    }

    return handle.length === 1 ? handle(prev) : handle(prev, action.payload);
  };
}

export { createReducer, describeAction };
